<template>
  <div class="pb-0 flex flex-wrap card-sidebar">
    <h2 class="title w-full pb-2">
      {{ $t("news") }}

      <span class="text-sm">
        <a href="https://b-emigrante.com/" style="color: black" target="_blank">
          site: b-emigrante.com
        </a>
      </span>
    </h2>
    <ProgressBar
      v-if="isLoading && $root.news.length == 0"
      class="text-center"
      mode="indeterminate"
    />

    <div
      v-for="(n, index) in $root.news"
      v-else
      :key="index"
      class="news-divider pb-3 mb-3 w-full card-content"
    >
      <div class="d-flow-root">
        <a
          v-if="
            n._embedded &&
            n._embedded['wp:featuredmedia'] &&
            n._embedded['wp:featuredmedia']['0'] &&
            n._embedded['wp:featuredmedia']['0'].source_url
          "
          :href="n.link"
          target="_blank"
          style="text-align: center; float: left"
          class="mb-3 w-full"
        >
          <div class="news-img">
            <img
              :src="n._embedded['wp:featuredmedia']['0'].source_url"
              class="news-image w-full"
            />
          </div>
        </a>
        <h4>
          <a class="news-link" :href="n.link" target="_blank">
            {{ formatTitle(n.title.rendered) }}
          </a>
        </h4>
        <span v-html="$sanitize(n.excerpt.rendered)" />
        <a
          :href="n.link"
          target="_blank"
          class="see_more cursor-pointer py-1 px-4 text-xs ml-auto"
          @click="openInNewTab"
        >
          {{ $t("see_more") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: true,
    };
  },
  created() {
    if (!this.$root.news.length) {
      this.getNews();
    }
  },
  methods: {
    getNews() {
      this.$root
        .apiWordpressBEGet(this.$root.api.externalApiBEmigrantePosts)
        .then((response) => {
          this.$root.news = response.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$root.toastError(error.response.data.msg_code);
        });
    },
    formatTitle(title) {
      return title.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
        return String.fromCharCode(charCode);
      });
    },
  },
};
</script>
